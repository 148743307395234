<template>
  <div class="boost-manager">
    <div id="boostBadgeContainer" v-if="isMobile"></div>
    <div id="boostContainer"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Boost',
  computed: {
    ...mapGetters([
      'user/user',
      'isMobile',
      'config',
    ]),
    userProfile() {
      return this['user/user'];
    },
  },
  mounted() {
    const boostConfig = this.config.ui.config.boost;
    const boostPayload = {
      tenantId: boostConfig.tenantId,
      modulesTheme: boostConfig.theme,
      environment: process.env.NODE_ENV,
      modulesContainerId: 'boostContainer',
      authorizationToken: this.userProfile.auth.token,
      widget: this.isMobile ? 'virtualsMobile' : 'virtuals',
      badgeSize: 'small',
      language: this.config.locale,
    };

    if (this.userProfile.profile.id) {
      boostPayload.profileId = this.userProfile.profile.id;
    }

    if (this.isMobile) {
      boostPayload.badgeContainerId = 'boostBadgeContainer';
    }

    window.NSoftBoost.configure(boostPayload);
  },
};
</script>

<style lang="scss" scoped>
.boost-manager {
  flex: 1;
  #boostBadgeContainer {
    position: absolute;
    display: flex;
    align-items: center;
    top: 5px;
    left: 80px;
  }
  #boostContainer {
    height: 100%;
  }
}

@media (max-width: 640px) {
  .boost-manager {
    #boostContainer {
      float: left;
      width: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      z-index: 100;
    }
  }
}
@media (min-width: 640px) and (max-width: 1200px) {
  .boost-manager {
    #boostContainer {
      height: 720px;
    }
  }
}
</style>
